<template>
  <b-overlay :show="showLoading" spinner-variant="primary">
    <!-- <b-card> -->

    <div class="d-flex justify-content-start" :key="picKey">
      <b-avatar :src="optionsLocal.profilePic" :text="avatarText(optionsLocal.firstName + ' ' + optionsLocal.lastName)"
        size="104px" rounded variant="light-primary" />
      <div class="d-flex flex-column ml-1">
        <div class="mb-1">
          <h4 class="mb-0">
            {{ optionsLocal.firstName + ' ' + optionsLocal.lastName }}
          </h4>
          <span class="card-text">{{ optionsLocal.email }}</span>
        </div>
        <div class="d-flex flex-wrap">
          <b-button variant="primary" @click="$refs.refInputEl.click()">
            <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer">
            <span class="d-none d-sm-inline">Modifier</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
          <b-button variant="outline-danger" class="ml-1" @click="optionsLocal.profilePic = ''"
            :disabled="optionsLocal.profilePic == ''">
            Supprimer
          </b-button>
        </div>
      </div>
    </div>
    <br>

    <!-- <b-row>

        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar :src="optionsLocal.profilePic" :text="avatarText(optionsLocal.firstName + ' ' + optionsLocal.lastName)"
            variant="light-primary" size="104px" rounded />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  userData.fullName 
                </h4>
                <span class="card-text">>{{ optionsLocal.email }} </span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button  variant="primary">
                  Edit
                </b-button>
                <b-button variant="outline-danger" class="ml-1">
                  Delete
                </b-button>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="DollarSignIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  23.3k
                </h5>
                <small>Monthly Sales</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar variant="light-success" rounded>
                <feather-icon icon="TrendingUpIcon" size="18" />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  $99.87k
                </h5>
                <small>Annual Profit</small>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Username</span>
              </th>
              <td class="pb-50">
                 userData.username 
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50 text-capitalize">
                userData.status 
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Role</span>
              </th>
              <td class="pb-50 text-capitalize">
                userData.role
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
                <span class="font-weight-bold">Country</span>
              </th>
              <td class="pb-50">
                country
              </td>
            </tr>
            <tr>
              <th>
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">Contact</span>
              </th>
              <td>
                ddd
              </td>
            </tr>
          </table>
        </b-col>
      </b-row> -->


    <b-form class="mt-2">
      <b-row>
        <b-col sm="4">
          <b-form-group label="Prénom" label-for="account-name">
            <b-form-input v-model="optionsLocal.firstName" name="name" placeholder="Prénom" />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Nom" label-for="account-name">
            <b-form-input v-model="optionsLocal.lastName" name="name" placeholder="Nom" />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input v-model="optionsLocal.email" name="email" placeholder="Email" />

          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Numéro de contact de la pharmacie" label-for="aphone">
            <b-form-input v-model="optionsLocal.phoneNo" name="phone" placeholder="Téléphone" />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Numéro de portable" label-for="aphone">
            <b-form-input v-model="optionsLocal.mobilePhone" name="phone" placeholder="Téléphone" />
          </b-form-group>
        </b-col>
        <b-col sm="4">
          <b-form-group label="Code postal" label-for="account-username">
            <b-form-input v-model="optionsLocal.postalCode" placeholder="Code postal" name="postalCode" />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Adresse" label-for="address">
            <b-form-input v-model="optionsLocal.address" name="address" placeholder="Adresse" />
          </b-form-group>
        </b-col>

        <!-- buttons -->
        <b-col cols="12" class="text-right d-md-block d-none">
          <b-button variant="success" class="mt-1 mr-1" @click="updateGeneralInfo()">
            Sauvegarder les modifications
          </b-button>
          <b-button @click="resetForm" variant="outline-secondary" class="mt-1">
            Réinitialiser
          </b-button>
        </b-col>

        <b-col cols="12" class="d-inline d-sm-none">
          <b-button @click="resetForm" variant="outline-secondary" class="mt-4" block>
            Réinitialiser
          </b-button>
        </b-col>

        <b-col cols="12" class="d-inline d-sm-none">
          <b-button variant="success" class="mt-1 mr-1" @click="updateGeneralInfo()" block>
            Sauvegarder les modifications
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    <!-- </b-card> -->
  </b-overlay>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { avatarText } from '@core/utils/filter'

export default {
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      picKey: 0,
      avatarText: avatarText,
      showLoading: true,
      optionsLocal: {
        id: "",
        address: "",
        postalCode: "",
        customerNo: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNo: "",
        mobilePhone: "",
        role: "",
        username: "",
        profilePic: ""
      }
    }
  },
  created() {
    if (this.$store.getters["app-userdata/getAppActiveUser"].username) {
      this.showLoading = false;
      this.optionsLocal = this.$store.getters["app-userdata/getAppActiveUser"];
    }
  },
  computed: {
    findData() {
      return this.$store.getters["app-userdata/getAppActiveUser"];
    },
  },
  watch: {
    findData(data) {
      this.optionsLocal = data;
      this.showLoading = false;
    }
  },
  methods: {
    inputImageRenderer(event) {
      // Assuming event.target.files[0] is the selected image file
      const selectedImageFile = event.target.files[0];
      if (selectedImageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // Update the avatar source when a new image is selected
          this.optionsLocal.profilePic = e.target.result;
          this.picKey += 1;
        };
        reader.readAsDataURL(selectedImageFile);
      }
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.$store.state["app-userdata"].AppActiveUser));
      this.picKey += 1;
    },
    updateGeneralInfo() {
      this.showLoading = true;
      this.$http.post("account/updateInfo", this.optionsLocal).then(() => {
        this.showLoading = false;
        this.showToast('success', 'CheckIcon', 'Mise à jour', "Vos informations ont été modifiés");
        this.$store.dispatch('app-userdata/INIT_USER_DATA');
      }).catch((err) => {
        this.showLoading = false;
        console.log(err.response);
        this.showToast('danger', 'AlertOctagonIcon', 'Mise à jour', err.response.data)
      });
    },
    showToast(variant, icon, title, text) {
      this.$toast.clear();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon,
          text: text,
          variant,
        },
      });
    },
  },

}
</script>
