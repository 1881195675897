<template>
  <b-card>
    <b-tabs pills>
      <!-- general tab -->
      <b-tab active v-if="!isAdministrator">

        <!-- title -->
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Informations Générales</span>
        </template>

        <br>
        <account-setting-general :general-data="options.general" />
      </b-tab>
      <!--/ general tab -->
      <!-- change password tab -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Informations d'Identification</span>
        </template>

        <br>
        <account-setting-password />
      </b-tab>
      <!--/ change password tab -->
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import { isAdministrator } from "@/auth/utils";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,

  },
  data() {
    return {
      isAdministrator: isAdministrator(),
      options: {},
    }
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
