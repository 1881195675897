<template>
  <b-overlay :show="showLoading" spinner-variant="primary">
    <!-- <b-card> -->
    <!-- form -->
    <b-form>
      <b-row>
        <b-col md="6">
          <b-form-group label="Nom d'utilisateur">
            <b-input-group>
              <b-form-input v-model="username" name="Nom d'utilisateur" disabled />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Ancien mot de passe" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-old-password" v-model="passwordValueOld" name="Ancien mot de passe"
                :type="passwordFieldTypeOld" placeholder="Ancien mot de passe" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
        <!-- </b-row>
      <b-row> -->
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="account-new-password" label="Nouveau mot de passe">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew"
                name="Nouveau mot de passe" placeholder="Nouveau mot de passe" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group label-for="account-retype-new-password" label="Nouveau mot de passe">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-retype-new-password" v-model="RetypePassword" :type="passwordFieldTypeRetype"
                name="Nouveau mot de passe" placeholder="Nouveau mot de passe" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- alert -->
        <b-col v-if="showErrorAlert" cols="12" class="mt-75">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Verifier vos informations, Les mots de passe ne sont pas identiques
            </h4>
            <div class="alert-body" v-show="false">
              <b-link class="alert-link">
                Les mots de passe ne sont pas identiques
              </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <!-- buttons -->
        <b-col cols="12" class="text-right d-md-block d-none">
          <b-button variant="success" class="mt-1 mr-1" @click="updatePassword()">
            Sauvegarder les modifications
          </b-button>
          <b-button type="reset" variant="outline-secondary" class="mt-1">
            Réinitialiser
          </b-button>
        </b-col>

        <b-col cols="12" class="d-inline d-sm-none">
          <b-button type="reset" variant="outline-secondary" class="mt-4" block>
            Réinitialiser
          </b-button>
        </b-col>

        <b-col cols="12" class="d-inline d-sm-none">
          <b-button variant="success" class="mt-1 mr-1" @click="updatePassword()" block>
            Sauvegarder les modifications
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    <!-- </b-card> -->
  </b-overlay>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  data() {
    return {
      showLoading: false,
      showErrorAlert: false,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    username() {
      return JSON.parse(localStorage.getItem("userData")).username;
    },
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    updatePassword() {
      if (this.newPasswordValue != this.RetypePassword) {
        this.showErrorAlert = true
        return
      }
      else
        this.showErrorAlert = false
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, modifier le mot de passe',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        title: 'Mise à jour mot de passe',
        text: 'Enregistrer la modification',


      }).then(resp => {
        if (resp.value) {
          this.onConfirmPasswordupdate()
        }
      })

    },
    onConfirmPasswordupdate() {
      this.showLoading = true;
      let compte = {
        username: JSON.parse(localStorage.getItem('userData')).username,
        password: this.passwordValueOld,
        passwordNew: this.newPasswordValue
      }
      this.$http.post("account/updatePassword", compte).then((resp) => {
        this.showLoading = false;
        console.log(resp)
        this.showToast('success', 'CheckIcon', 'Mise à jour', "Mot de passe modifié")
        this.passwordValueOld = ''
        this.newPasswordValue = ''
        this.RetypePassword = ''
      }).catch(() => {
        this.showLoading = false;
        // console.log(err.response);
        // this.showToast('danger', 'AlertOctagonIcon', 'Mise à jour', err.response.data)
      });

    },
    showToast(variant, icon, title, text) {
      this.$toast.clear();
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon,
          text: text,
          variant,
        },
      });
    },
  },
}
</script>
